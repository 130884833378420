import React, { useState, useEffect } from "react";
import "../assets/style/globalStyle.scss";
import NavBar from "../components/navBarSite";
import Footer from "../components/footer";
import BlogCard from "../components/blogCard";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import background from "../assets/img/background.png";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Vertical_rectangle from "../assets/img/Vertical_rectangle.png";

//Ads
import PumaAdsVertical from "../assets/ads/puma_240x400.jpg";

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

const BlogPage = ({ data, pageContext }) => {
  const isPhone = useMedia("(max-width: 767px)");
  const isLaptop = useMedia("(min-width: 1225px)");
  const { posts } = data.blog;
  const { filters } = data.categories;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  const ButtonFilter = styled.p`
    color: black;
    font-weight: 500;
    font-size: 14px;
    background: white;
    min-width: 82px;
    margin-left: 6px;
    margin-right: 6px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px 36px 36px 36px;
    padding: 0px 15px 0px 15px;
    :hover {
      cursor: pointer;
      background: #aa6280;
      color: white;
    }
  `;

  const styles = {
    container: {
      margin: 0,
      padding: 0,
      fontFamily: "Montserrat",
    },
    background: {
      display: "flex",
      flexDirection: "column",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.8),
      rgba(161,84,119, 0.8)),url(${background})`,
      height: "650px",
      marginTop: 73,
      padding: "145px 0px 0px 0px",
      alignItems: "center",
    },
    searchInput: {
      fontFamily: "Montserrat",
      width: isPhone ? "70%" : 400,
      outlineColor: "rgba(252, 206, 192, 1)",
      border: "none",
      borderRadius: "5px 0px 0px 5px",
      height: 45,
      padding: 21,
      fontSize: "16px",
      fontWeight: 400,
    },
    divider: {
      border: "1px solid #E0E0E0",
      width: !isLaptop ? "100%" : "100%",
      marginBottom: 16,
    },
    categoriesName: {
      fontSize: "16px",
      fontWeight: 400,
      color: "black",
      marginBottom: 16,
    },
    categoriesNbr: {
      fontSize: "18px",
      fontWeight: 600,
      color: "#F56D44",
      marginBottom: 16,
    },
  };

  return (
    <div style={styles.container}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <NavBar></NavBar>

      {/* BACKGROUND */}
      <div style={styles.background}>
        <h1
          style={{
            fontWeight: "800",
            fontSize: "58px",
            color: "white",
            marginBottom: 15,
          }}
        >
          Notre blog
        </h1>
        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "white",
            textAlign: "center",
            width: "80%",
            marginBottom: 35,
          }}
        >
          Retrouvez ici l'intégralité de nos blogs, ainsi que les blogs de nos
          partenaires réalisés pour la communautée et les fans de basketball.
        </p>
        {/* <Formik
          initialValues={{
            search: "",
          }}
          onSubmit={async (values) => {
            searchArticle(values.search);
          }}>
          {({
            setFieldValue,
            handleSubmit,
            values,
          }) => (
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignSelf: "center",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}>
              <input
                value={values.search}
                type="search"
                name="mail"
                placeholder="Rechercher..."
                style={styles.searchInput}
                onChange={(e) => {
                  setFieldValue("search", e.target.value);
                }}
              />
              <ButtonSearch onClick={() => { handleSubmit(); }}>RECHERCHER</ButtonSearch>
            </div>
          )}
        </Formik> */}
        {isPhone ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 35,
              }}
            >
              {filters.slice(0, 3).map((filters) => (
                <a href={filters.fieldValue.toLowerCase()}>
                  <ButtonFilter>{filters.fieldValue}</ButtonFilter>
                </a>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 35,
              }}
            >
              {filters.slice(filters.length - 2).map((filters) => (
                <a href={filters.fieldValue.toLowerCase()}>
                  <ButtonFilter>{filters.fieldValue}</ButtonFilter>
                </a>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 35,
            }}
          >
            {filters.map((filters) => (
              <>
                <a href={filters.fieldValue.toLowerCase()}>
                  <ButtonFilter>{filters.fieldValue}</ButtonFilter>
                </a>
              </>
            ))}
          </div>
        )}
      </div>

      <div
        style={{
          paddingRight: isPhone ? 30 : 100,
          paddingLeft: isPhone ? 30 : 100,
          paddingBottom: 55,
          paddingTop: 64,
          display: "flex",
          flexDirection: isLaptop ? "row" : "column",
          justifyContent: "space-between",
          marginBottom: 70,
        }}
      >
        {!isLaptop && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 75,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: 800,
                  color: "black",
                  marginBottom: 45,
                }}
              >
                Catégorie
              </p>
              {filters.map((filters) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <a href={filters.fieldValue.toLowerCase()}>
                      <p style={styles.categoriesName}>{filters.fieldValue}</p>
                    </a>
                    <p style={styles.categoriesNbr}>{filters.totalCount}</p>
                  </div>
                  <div style={styles.divider}></div>
                </>
              ))}
              {/* <div
                style={{
                  width: 240,
                  height: 400,
                  backgroundImage: "../assets/ads/puma_240x400.jpg",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <a
                  target="blank"
                  href="https://ballnconnect.com/?p=Event&q=6310806bf324de0018a96d42"
                  onClick={() => window.gtag("event", "clickPumaAd", { name: "blogHome" })}
                >
                  <img
                    src={PumaAdsVertical}
                    alt="Puma ads"
                    style={{ width: 240, height: 400 }}
                  />
                </a>
              </div> */}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {posts.slice(0, 3).map((post) => (
            <BlogCard
              key={post.id}
              categorie={post.frontmatter.category}
              img={post.frontmatter.image}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              content={post.excerpt}
              name={post.frontmatter.author}
              link={post.frontmatter.slug}
            ></BlogCard>
          ))}
        </div>
        {isLaptop && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "35%",
              marginLeft: 70,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: 800,
                  color: "black",
                  marginBottom: 45,
                }}
              >
                Catégorie
              </p>
              {filters.map((filters) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <a href={filters.fieldValue.toLowerCase()}>
                      <p style={styles.categoriesName}>{filters.fieldValue}</p>
                    </a>
                    <p style={styles.categoriesNbr}>{filters.totalCount}</p>
                  </div>
                  <div style={styles.divider}></div>
                </>
              ))}
              {/* {isPhone ? (
                <div
                  style={{
                    width: 240,
                    height: 400,
                    backgroundImage: "../assets/img/Vertical_rectangle.png",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <a
                    target="blank"
                    href="http://onelink.to/fsbntf"
                    onClick={() =>
                      window.gtag("event", "clickPumaAdMobile", {
                        name: "blogHome",
                      })
                    }
                  >
                    <img
                      src={Vertical_rectangle}
                      alt="Shinzo ads"
                      style={{ width: 240, height: 400 }}
                    />
                  </a>
                </div>
              ) : (
                <div
                  style={{
                    width: 240,
                    height: 400,
                    backgroundImage: "../assets/img/Vertical_rectangle.png",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <a
                    target="blank"
                    href="http://onelink.to/fsbntf"
                    onClick={() =>
                      window.gtag("event", "clickPumaAd", { name: "blogHome" })
                    }
                  >
                    <img
                      src={Vertical_rectangle}
                      alt="Shinzo ads"
                      style={{ width: 240, height: 400 }}
                    />
                  </a>
                </div>
              )} */}
            </div>
          </div>
        )}
      </div>

      <ul
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!isFirst && (
          <a href={prevPage}>
            <div
              style={{
                width: "32px",
                height: "32px",
                background: "#FFFFFF",
                fontWeight: "bold",
                border: "1px solid #DFE3E8",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#DFE3E8",
              }}
            >
              <Icon icon={faAngleLeft} />
            </div>
          </a>
        )}
        {Array.from({ length: numPages }, (_, i) => (
          <li
            key={`pagination-number${i + 1}`}
            style={{
              padding: "4px",
            }}
          >
            <a href={`/blog/${i === 0 ? "" : i + 1}`}>
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  background: "#FFFFFF",
                  fontWeight: "bold",
                  border:
                    i + 1 === currentPage
                      ? "1px solid #F56D44"
                      : "1px solid #DFE3E8",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: i + 1 === currentPage ? "#F56D44" : "black",
                }}
              >
                {i + 1}
              </div>
            </a>
          </li>
        ))}
        {!isLast && (
          <a href={nextPage}>
            <div
              style={{
                width: "32px",
                height: "32px",
                background: "#FFFFFF",
                fontWeight: "bold",
                border: "1px solid #DFE3E8",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#DFE3E8",
              }}
            >
              <Icon icon={faAngleRight} />
            </div>
          </a>
        )}
      </ul>

      {/* FOOTER*/}
      <Footer></Footer>
    </div>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query MyQuery($skip: Int!, $limit: Int!) {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true, locale: "FR")
          title
          author
          category
          slug
          image
        }
        excerpt(pruneLength: 400)
        id
      }
    }
    categories: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      filters: group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`;
