import React, { useState, useEffect } from "react";
import arrowRight from "../assets/img/arrow_right.png";

const styles = {
    container: {
        maxWidth: "755px",
        borderRadius: "5px",
        marginBottom: 25,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        border: "1px solid #D2D2D2"
    },
    title: {
        fontSize: "24px",
        fontWeight: 800,
        color: "black",
        marginTop: 13
    },
    content: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
        marginTop: 18,
        textAlign: "justify"
    },
    image: {
        borderRadius: 50,
        width: 60
    },
    date: {
        fontSize: "14px",
        fontWeight: 400,
        color: "black",
        opacity: 0.5,
        marginTop: 20
    },
    link: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "#F56D44",
        fontSize: "14px",
        fontWeight: 700,
        marginBottom: 25,
        marginTop: 25
    },
    categorie: {
        color: "white",
        fontWeight: 700,
        fontSize: "18px",
        backgroundColor: "#F56D44",
        borderRadius: "33px",
        height: "44px",
        width: "165px",
        position: "sticky",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        marginLeft: 25,
        marginTop: -20,
        textAlign: "center",
    }
}

const BlogCard = ({
    title,
    content,
    date,
    img,
    name,
    categorie,
    link
}) => {
    function useMedia(query) {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => {
                setMatches(media.matches);
            };
            media.addEventListener('change', listener);
            return () => media.removeEventListener('change', listener);
        }, [matches, query]);

        return matches;
    }

    const isPhone = useMedia('(max-width: 767px)');
    return (
        <div style={styles.container}>
            <img
                src={img}
                alt="article image"
                style={{ height: 341, objectFit: "cover" }}
            ></img>
            <div style={styles.categorie}>{categorie}</div>
            <div style={{
                paddingLeft: 25, paddingRight: 25, display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
            }}>
                <div>
                    <p style={styles.date}>{date} par {name}</p>
                    <p style={styles.title}>{title}</p>
                    <p style={styles.content}>{isPhone ? content.substring(0, 250) + "..." : content}</p>
                </div>
                <div>
                    <a href={link} style={styles.link} onClick={() => window.gtag("event", "clickCategorie" + categorie, { name: "blogHome" })}>LIRE PLUS
                        <img
                            src={arrowRight}
                            alt="profile"
                            style={{ marginLeft: 14 }}
                        ></img>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
